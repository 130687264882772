import styles from "./home2.module.scss";

export default function Investors() {
  return (
    <div className={styles.layout}>
      <header className={styles.home__header}>
        <img src="/logo192.png" />
      </header>

      <div className={styles.investment__title}>
        SOWIZ SOLUTIONS PRIVATE LIMITED
      </div>
      <div className={styles.investment__subtitle}>
        <strong>Regd. Address:</strong> House No. PVT No. 06, Plot No-53 Ground
        floor
      </div>
      <div className={styles.investment__subtitle}>
        BLK- A Rama Road, Najafgarh Road, South Delhi, Delhi, India – 110015
      </div>
      <div className={styles.investment__justtitle}>
        <strong>CIN:</strong> U72900DL2022FTC408348
      </div>
      <div className={styles.investment__justtitle}>
        Audited Standalone Financial Results of Sowiz Solutions Private Limited
        For the Last Three Years
      </div>
      <div className={styles.investment__million}>
        (Rs. in million except per share data)
      </div>
      <div className={styles.investment__list}>
        <table>
          <thead>
            <tr>
              <th></th>
              <th className="align__right">Financial Year 2024</th>
              <th className="align__right">Financial Year 2023</th>
              <th className="align__right">Financial Year 2022</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Reserves (Excluding Revaluation Reserve)</td>
              <td className="align__right">(0.43)*</td>
              <td className="align__right">(0.01)*</td>
              <td className="align__right">-</td>
            </tr>
            <tr>
              <td>Sales</td>
              <td className="align__right">8.86</td>
              <td className="align__right">-</td>
              <td className="align__right">-</td>
            </tr>
            <tr>
              <td>Profit/(Loss) after Tax</td>
              <td className="align__right">(0.42)*</td>
              <td className="align__right">(0.01)*</td>
              <td className="align__right">-</td>
            </tr>
            <tr>
              <td>Earning per Share (Basic) (Face Value of Rs. 10)</td>
              <td className="align__right">0.04</td>
              <td className="align__right">-</td>
              <td className="align__right">-</td>
            </tr>
            <tr>
              <td>Earning per Share (Diluted) (Face Value of Rs. 10)</td>
              <td className="align__right">0.04</td>
              <td className="align__right">-</td>
              <td className="align__right">-</td>
            </tr>
            <tr>
              <td>Net Assets Value</td>
              <td className="align__right">(33.24)*</td>
              <td className="align__right">9.00</td>
              <td className="align__right">-</td>
            </tr>
          </tbody>
          <tfoot>
            <p style={{ fontWeight: 700 }}>*-ve figures are in brackets</p>
          </tfoot>
        </table>
      </div>
    </div>
  );
}
