import { useEffect, useRef, useState } from "react";

export default function BlurAnimate({ children, className }) {
  const ref = useRef();
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (ref.current) {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setShow(true);

            observer.unobserve(entry.target);
          }
        });
      });

      observer.observe(ref.current);
    }
  }, [ref]);

  return (
    <section ref={ref} className={`${className} ${show && "blurUpAnimation"}`}>
      {children}
    </section>
  );
}
