import "./App.css";
import { useEffect, useState } from "react";

import Home from "./v2/HomeV2";
import Hiring from "./v2/Hiring";
import Investors from "./v2/Investors";
import Terms from "./v2/Terms";
import Privacy from "./v2/Privacy";
import { Helmet } from "react-helmet";

import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
} from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "terms",
    element: <Terms />,
  },
  {
    path: "privacy",
    element: <Privacy />,
  },
  {
    path: "career",
    element: <Hiring />,
  },
  {
    path: "investors",
    element: <Investors />,
  },
  {
    path: "*",
    element: (
      <div className="notFound">
        <h3>No page found</h3>
        <a href="/">Go back to home</a>
      </div>
    ),
  },
]);

function App() {
  useEffect(() => {
    document.title = "Sowiz";
  }, []);

  return (
    <>
      <Helmet>
        <title>Sowiz</title>
      </Helmet>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
