import ScreenHeight90 from "../ScreenHeightContainer";
import styles from "./terms.module.scss";
import back from "../icons/back.svg";

export default function PrivacyPolicy() {
  return (
    <ScreenHeight90 ratio={1} className={styles.terms}>
      <div
        onClick={() => {
          window.location.href = "/";
        }}
        className={styles.terms__head}
      >
        <img src={back} />
      </div>
      <div className={styles.terms__back}>
        <img src="/service.png" />
      </div>
      <div className={styles.terms__main}>
        <p className={styles.terms__main__title}>PRIVACY POLICY</p>

        <article>
          <p>
            Welcome to Sowiz. This document explains what personal data we
            collect from you, how we use it, and your rights in relation to it.
            By using Sowiz, you agree to the collection and use of your
            information in accordance with this Privacy Policy.
          </p>
          <span class="text_subHeading__iii9m undefined">Permissions</span>
          <p>
            <strong>Contacts Access:</strong>The app requests access to your
            device's and google account contact list. This permission is
            essential to enable the referral feature, which allows you to invite
            friends directly. Importantly, your contact data is stored on
            secured servers and protected by secured networks to which access is
            limited to a few authorized employees and personnel. Without your
            explicit consent, no contact information is accessed or shared. By
            accessing your contacts:
          </p>
          <ul>
            <li>
              You can easily select individuals from your address book without
              manually entering their details.
            </li>
            <li>
              The app can identify frequently used or favorite contacts to make
              the referral process more seamless.
            </li>
            <li>
              This access ensures that the app can notify you about referral
              rewards and status updates when applicable.
            </li>
          </ul>
          <p>
            We use cookies and similar technologies to enhance your experience,
            gather general visitor information, and track visits to our app.
          </p>
          <p>
            <strong>Profile Information:</strong> Your profile data is handled
            securely and used only for improving your app experience. We do not
            share or sell this data to any third party. To personalize your
            experience within Sowiz, we request access to your Google profile
            information, including your name, email address, and profile
            picture. This scope enables us to
          </p>
          <ul>
            <li>
              Automatically populate your user profile upon registration for a
              faster onboarding process.
            </li>
            <li>
              Identify you uniquely across the app to maintain accurate referral
              tracking and reward allocation.
            </li>
            <li>
              Customize in-app features and communication based on your profile.
            </li>
          </ul>
          <p>
            <strong>Email Address:</strong>Access to your email address is
            crucial for account creation, login, and communication purposes.
            Your email address is stored securely, and you retain full control
            over how it is used within the app. We use this information to:
          </p>
          <ul>
            <li>
              Provide a secure authentication process, including password
              recovery.
            </li>
            <li>
              Send important account-related notifications, such as updates on
              referrals, changes to terms, or service disruptions.
            </li>
            <li>
              Share optional promotional offers, which you can choose to opt-out
              of at any time.
            </li>
          </ul>
          <span class="text_subHeading__iii9m undefined">Privacy Policy</span>
          <p>
            At Sowiz, we are committed to protecting the privacy of our users
            and ensuring that your personal information and contacts are handled
            responsibly. This Privacy Policy explains in detail how we collect,
            use, share, and protect the information you provide to us. By using
            our services, you agree to the practices described in this policy.
            If you do not agree, please discontinue using the application.
          </p>
          <p>
            <strong>Information We Collect:</strong>
          </p>
          <ul>
            <li>
              To deliver our services effectively and provide a seamless
              experience, we collect certain types of information from you. This
              includes both information you actively provide and data we gather
              automatically when you use the application.
            </li>
            <li>
              When you register with Sowiz, you are required to provide basic
              information such as your first name, last name, and phone number.
              This information forms the basis of your user profile and is
              essential for account creation and using our services.
              Additionally, you may choose to provide optional information, such
              as your profile photo, gender, address, and email. This optional
              data enhances your experience by allowing us to personalize
              features and improve the usability of the app.
            </li>
            <li>
              During the use of our app, we also automatically collect certain
              data related to your device and usage. This includes details about
              your device, such as its IP address, unique device identifiers,
              manufacturer, model, operating system, browser type, and network
              operator. We monitor how you interact with the app by tracking the
              features you access, pages you view, and the search terms you use.
              We may also collect metadata related to your calls and messages,
              such as timestamps and contact information. Additionally, with
              your explicit consent, we may collect location data to enable
              certain features that rely on geolocation services. All of this
              data helps us understand how the app is being used and allows us
              to continuously improve its functionality.
            </li>
            <li>
              In certain cases, you may link your Sowiz account to third-party
              services, such as social networks or payment platforms. By doing
              so, you allow us to access specific information from these
              platforms, such as your user ID, public profile details, and any
              interactions or shared content relevant to our app's features.
              This is done in accordance with the privacy policies of the
              respective third-party services.
            </li>
            <li>
              Lastly, with your explicit consent, we may access your device's
              contact information to facilitate features like referrals and
              contact management. However, we ensure that this information
              remains solely on your device and is not uploaded or stored on our
              servers.
            </li>
          </ul>
          <p>
            <strong>How We Use Your Information:</strong>
          </p>
          <ul>
            <li>
              The information we collect is used to deliver and improve the
              services provided by Sowiz. By gathering both user-provided and
              automatically collected data, we aim to ensure that our app
              performs as expected and meets the needs of our users.
            </li>
            <li>
              First and foremost, the data you provide allows us to offer and
              enhance the core functionality of our app. It helps us ensure that
              the services operate smoothly, any technical issues are addressed
              promptly, and the user interface is optimized for your experience.
              For example, we use your contact information to enable features
              like referrals, allowing you to easily invite friends and
              colleagues to use various services through the app.
            </li>
            <li>
              Your personal data also helps us personalize the app for you. By
              understanding your preferences and behavior, we can tailor
              features, notifications, and suggestions to match your specific
              needs. This level of customization ensures that your experience
              with Sowiz feels intuitive and relevant.
            </li>
            <li>
              In addition, we may use your information to facilitate
              interactions between users, such as connecting you with referrals
              or shared contacts within the app. We also communicate with you to
              provide updates, send promotional offers, or assist with customer
              support inquiries. All such communications are aimed at ensuring
              you stay informed and receive the best possible experience.
            </li>
            <li>
              Finally, we analyze usage patterns and user feedback to enhance
              the overall functionality of the app. This includes identifying
              areas of improvement, introducing new features, and ensuring that
              the app remains compliant with legal and security standards. We
              are committed to using your data responsibly and transparently to
              provide a secure, user-focused service.
            </li>
          </ul>
          <p>
            <strong>Sharing Your Information</strong>
          </p>
          <ul>
            <li>
              At Sowiz, we take the privacy of your data seriously and do not
              sell or rent your personal information under any circumstances.
              However, there are limited scenarios where sharing data is
              necessary to deliver our services or comply with legal
              obligations.
            </li>
            <li>
              We may share your information with trusted service providers who
              assist us in operating the app and providing services to you.
              These service providers are bound by strict confidentiality
              agreements and are only permitted to use your data for purposes
              directly related to the app's functionality. For example, we may
              rely on cloud storage providers to securely store information or
              analytics services to understand user behavior and improve the
              app.
            </li>
            <li>
              In cases where required by law or to protect the rights and safety
              of our users, we may disclose your information to legal
              authorities. This may occur in response to a legal process, such
              as a court order or subpoena, or in situations where disclosure is
              necessary to prevent fraud, address security concerns, or
              safeguard the integrity of our app.
            </li>
            <li>
              In the event of a business transfer, such as a merger,
              acquisition, or sale of assets, your personal information may be
              transferred to the relevant third party. In such cases, we will
              notify you and ensure that the new entity honors the commitments
              made in this Privacy Policy.
            </li>
          </ul>
          <p>
            <strong>User Rights</strong>
          </p>
          <ul>
            <li>
              At Sowiz, we are committed to respecting and upholding your rights
              as provided under the Digital Personal Data Protection Act, 2023.
              As a user, you have the right to access, correct, transfer, and
              erase your personal data that we process. Upon request, and where
              feasible, we will provide you with a copy of the personal data we
              hold about you in a structured, commonly used, and
              machine-readable format. Additionally, you may request the
              deletion of your data when it is no longer necessary for the
              purpose for which it was collected or if you withdraw your
              consent. Such requests will be honored unless we are legally
              obligated to retain the data. These rights can be exercised by
              contacting us at the grievance redressal contact provided below.
            </li>
          </ul>
          <p>
            <strong>Data Retention</strong>
          </p>
          <ul>
            <li>
              We retain personal data only for as long as necessary to fulfill
              the purposes for which it was collected or to comply with legal,
              regulatory, or contractual obligations. For account-related data,
              we will retain the information for the duration of your active use
              of our services. Upon account termination or inactivity exceeding
              180 days, your personal data will be securely deleted or
              anonymized within 30 days, unless otherwise required by law. For
              data processed based on your consent, such as marketing
              communications, we will retain the data until you withdraw your
              consent. Metadata and usage logs that do not identify you
              personally may be retained for analytical purposes, provided it
              complies with applicable legal standards.
            </li>
          </ul>
          <p>
            <strong>Consent Management</strong>
          </p>
          <ul>
            <li>
              We process your personal data only after obtaining your explicit
              and informed consent. By agreeing to this Privacy Policy and
              granting permissions through your device settings or app
              interfaces, you provide consent for the collection, use, and
              sharing of your data as described. You retain the right to
              withdraw your consent at any time through the app settings or by
              contacting us directly. Upon withdrawal, we will cease processing
              your data for the purposes to which the consent applied, unless
              there is a legal obligation or legitimate interest to continue
              processing. The app ensures that consent is obtained in a granular
              manner, with users having the ability to choose specific
              permissions for data types, such as contacts, profile information,
              and geolocation. Consent must be freely given and is revocable at
              your discretion, without affecting the legality of data processing
              conducted prior to the withdrawal.
            </li>
          </ul>
          <p>
            <strong>Cross-Border Data Transfers</strong>
          </p>
          <ul>
            <li>
              If any of your personal data is transferred outside India, such
              transfers will be conducted in strict compliance with applicable
              legal standards under the Digital Personal Data Protection Act,
              2023. We will ensure that any data shared or stored
              internationally is afforded the same level of protection as
              mandated by Indian law. This includes ensuring that the recipient
              country or organization maintains adequate security and privacy
              safeguards. When such transfers occur, users will be informed
              through updates to this privacy policy. Additionally, we will use
              legally recognized mechanisms, such as data processing agreements,
              to ensure that your data is protected in accordance with this
              Privacy Policy and applicable regulations. We remain committed to
              securing your data regardless of its location.
            </li>
          </ul>
          <span class="text_subHeading__iii9m undefined">
            Terms of Services
          </span>
          <p>
            <strong>About us:</strong>Sowiz is a platform designed to enhance
            user interactions and provide features such as referrals,
            personalized recommendations, and communication tools. We aim to
            offer a seamless and user-friendly experience, built on principles
            of transparency and trust. By using our Services, you acknowledge
            that you understand and agree to abide by these Terms.
          </p>
          <p>
            <strong>User Responsibilities:</strong> When using Sowiz, you are
            responsible for maintaining the confidentiality of your account
            credentials and for any activities that occur under your account.
            You agree to notify us immediately of any unauthorized use or
            suspected breach of security. You must ensure that your use of the
            Services complies with all applicable laws and regulations. Misuse
            of the Services, including but not limited to creating fake
            accounts, engaging in fraudulent referrals, or introducing malicious
            software, is strictly prohibited. You agree not to disrupt or
            interfere with the functioning of Sowiz, harm its infrastructure, or
            compromise the experience of other users. Any behavior deemed
            harmful, illegal, or contrary to these Terms may result in
            suspension or termination of your account.
          </p>
          <p>
            <strong>Permissions and Data Access: </strong>Sowiz may request
            access to certain permissions on your device to provide core
            functionalities, such as referrals, personalized notifications, and
            location-based features. Permissions include access to your
            contacts, location, and profile data. By granting these permissions,
            you enable us to deliver a more tailored and efficient experience.
            You may revoke these permissions at any time through your device
            settings, but certain features of the Services may become
            unavailable as a result. For detailed information about how we
            collect, use, and store your data, please refer to our Privacy
            Policy.
          </p>
          <p>
            <strong>Intellectual Property:</strong>All intellectual property
            rights in Sowiz, including the app’s design, code, features, logos,
            and content, are the exclusive property of Sowiz. You are granted a
            limited, non-transferable, non-exclusive license to use the Services
            solely for personal, non-commercial purposes. You may not copy,
            reproduce, distribute, or reverse-engineer any part of the Services
            without our prior written consent. If you provide feedback,
            suggestions, or ideas to improve Sowiz, you agree that we may use
            these submissions freely and without obligation to you. All such
            contributions become our intellectual property.
          </p>
          <p>
            <strong>Limitation of Liability:</strong>Sowiz is provided on an "as
            is" and "as available" basis, without any warranties, either express
            or implied. While we strive to maintain the app's performance and
            functionality, we do not guarantee uninterrupted or error-free
            operation. We are not responsible for any issues arising from
            technical difficulties, user behavior, or third-party interactions.
            To the extent permitted by law, Sowiz is not liable for any
            indirect, incidental, or consequential damages, including but not
            limited to loss of data, profits, or business opportunities. Your
            use of Sowiz is entirely at your own risk, and we encourage you to
            report any problems to us for resolution.
          </p>
          <p>
            <strong>Changes to the Terms:</strong>We reserve the right to modify
            the Privacy policy and these Terms from time to time to reflect
            changes in our Services, legal requirements, or business practices.
            When significant updates are made, we will notify you through the
            app. Continued use of Sowiz after such updates constitutes your
            acceptance of the revised Terms. If you do not agree to the updated
            Terms, you must discontinue using the Services.
          </p>
          <p>
            <strong>Termination:</strong>We may suspend or terminate your access
            to Sowiz at our discretion, with or without prior notice, if you
            violate these Terms, engage in misuse of the Services, or compromise
            the platform's integrity. Upon termination, your rights to use the
            Services will cease immediately, and any data associated with your
            account may be deleted in accordance with our Privacy Policy. You
            may terminate your account by uninstalling the app and ceasing all
            use of the Services.
          </p>
          <p>
            <strong>Contact Us:</strong>In compliance with the Digital Personal
            Data Protection Act, 2023, we have appointed a Grievance Officer to
            address concerns related to the processing of your personal data. If
            you have any complaints, queries, or requests regarding your data
            rights, you may contact the Grievance Officer via email or postal
            correspondence.:
          </p>
          <p>
            <strong>Email Address:</strong> support@sowiz.live
          </p>
          <p>
            <strong>Postal Address:</strong> SOWIZ SOLUTIONS PRIVATE LIMITED,
            53A/6, 1st &amp; 2nd Floor Rama Road, Najafgarh Road Industrial area
            , New Delhi - 110015
          </p>
          <p>
            Thank you for using Sowiz. We are committed to providing you with an
            exceptional experience and building a platform that meets your needs
            while respecting your privacy and rights.
          </p>
        </article>
        {/* <ul className={styles.terms__main__content}>
          <li>
            We are committed to protecting your privacy and ensuring the
            security of your personal information.
          </li>
          <li>
            This Privacy Policy outlines how we collect, use, and safeguard the
            data you provide to us.
          </li>
          <li>
            When you use our services, we may collect information such as your
            name, contact details, and usage data.
          </li>
          <li>
            This information is used to improve our services, respond to
            inquiries, and enhance your user experience.
          </li>
          <li>
            We do not share your personal information with third parties except
            as necessary to provide our services or as required by law.
          </li>
          <li>
            We implement robust security measures to protect your data from
            unauthorized access, alteration, or disclosure.
          </li>
          <li>
            By using our services, you consent to the collection and use of your
            information in accordance with this Privacy Policy.
          </li>
          <li>
            If you have any questions or concerns about our privacy practices,
            please contact us..
          </li>
        </ul> */}
      </div>
    </ScreenHeight90>
  );
}
