import ScreenHeight90 from "../ScreenHeightContainer";
import styles from "./hiring.module.scss";
import back from "../icons/back.svg";
import forward from "../icons/forward.svg";

export default function Hiring() {
  return (
    <ScreenHeight90 ratio={1} className={styles.career}>
      <div
        onClick={() => {
          window.location.href = "/";
        }}
        className={styles.career__head}
      >
        <img src={back} />
      </div>
      <div className={styles.career__back}>
        <img src="/service.png" />
      </div>
      <div className={styles.career__main}>
        <p className={styles.career__main__title}>Come join us</p>
        <p className={styles.career__main__subtitle}>Career Openings</p>
        <p className={styles.career__main__desc}>
          We’re always looking for creative, talented self-starters to join the
          Sowiz family. Check out our open roles below and fill out an
          application.
        </p>
        {/* <div className={styles.career__main__content}>
          <div className={styles.career__main__content__job}>
            <span className={styles.career__main__content__job__title}>
              Frontend engineer
            </span>
            <section className={styles.career__main__content__job__exp}>
              <span className={styles.career__main__content__job__exp__title}>
                Experience
              </span>
              <span className={styles.career__main__content__job__exp__details}>
                2+ years
              </span>
            </section>
            <div className={styles.career__main__content__job__action}>
              <img src={forward} />
            </div>
          </div>
          <div className={styles.career__main__content__job}>
            <span className={styles.career__main__content__job__title}>
              Frontend engineer
            </span>
            <section className={styles.career__main__content__job__exp}>
              <span className={styles.career__main__content__job__exp__title}>
                Experience
              </span>
              <span className={styles.career__main__content__job__exp__details}>
                2+ years
              </span>
            </section>
            <div className={styles.career__main__content__job__action}>
              <img src={forward} />
            </div>
          </div>
          <div className={styles.career__main__content__job}>
            <span className={styles.career__main__content__job__title}>
              Frontend engineer
            </span>
            <section className={styles.career__main__content__job__exp}>
              <span className={styles.career__main__content__job__exp__title}>
                Experience
              </span>
              <span className={styles.career__main__content__job__exp__details}>
                2+ years
              </span>
            </section>
            <div className={styles.career__main__content__job__action}>
              <img src={forward} />
            </div>
          </div>
          <div className={styles.career__main__content__job}>
            <span className={styles.career__main__content__job__title}>
              Frontend engineer
            </span>
            <section className={styles.career__main__content__job__exp}>
              <span className={styles.career__main__content__job__exp__title}>
                Experience
              </span>
              <span className={styles.career__main__content__job__exp__details}>
                2+ years
              </span>
            </section>
            <div className={styles.career__main__content__job__action}>
              <img src={forward} />
            </div>
          </div>
        </div> */}
      </div>
    </ScreenHeight90>
  );
}
